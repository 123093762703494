import styled from "styled-components"
import { colors, breakpoints } from "../../Style"
import { hexToRgb } from "../../../utils/"

export const Container = styled.header`
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  flex-flow: row nowrap;
  background-color: ${colors.white};
  box-shadow: 0 0.2rem 0 rgba(${hexToRgb(colors.blue).rgb}, 0.1);

  @media ${breakpoints.medium} {
    box-shadow: none;
  }
`

export const Headline = styled.h1`
  padding: 1.8rem 1.5rem 1.5rem;
  flex: 1;
  color: ${colors.blue};
  font-size: 1.6rem;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  text-align: center;

  @media ${breakpoints.medium} {
    padding-top: 1.7rem;
    font-size: 1.8rem;
  }

  @media ${breakpoints.large} {
    padding-top: 1.5rem;
    font-size: 2rem;
  }
`

export const Close = styled.button`
  position: relative;
  color: ${colors.white};
  border: none;
  background-color: ${colors.blue};
  width: 5rem;
  min-height: 5rem;
  cursor: pointer;

  &,
  &::before,
  &::after {
    transition: background-color 0.1s ease-in-out;
  }

  &::before,
  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    display: block;
    width: 2rem;
    height: 0.2rem;
    background-color: ${colors.white};
    content: "";
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:focus {
    outline: none;
  }

  &:focus,
  &:hover {
    background-color: ${colors.white};

    &::before,
    &::after {
      background-color: ${colors.blue};
    }
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &:not(:hover):focus {
    background-color: ${colors.blueFaded};
  }
`
