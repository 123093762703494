export default function fixObject(
  obj,
  { metalness, shadows, hidePlayer } = {
    metalness: false,
    shadows: false,
    hidePlayer: false,
  }
) {
  obj.traverse(function(child) {
    // fix metalness
    if (metalness) {
      if (child.material) {
        if (child.material.metalness) {
          child.material.metalness = 0
        }
      }
    }
    if (shadows) {
      // fix Shadow
      if (obj.type === "Mesh" && obj.name !== "Grass_Floor1") {
        obj.castShadow = true
      }

      if (obj.name === "Grass_Floor1") {
        obj.receiveShadow = true
      }
    }

    if (hidePlayer) {
      // hide players except the first one
      if (
        (obj.name.includes("man_step") &&
          obj.name !== "man_step" &&
          obj.name !== "man_step0") ||
        (obj.name.includes("Pilen") &&
          obj.name !== "Pilen" &&
          obj.name !== "Pilen0")
      ) {
        obj.visible = false
      }
    }
  })
}
