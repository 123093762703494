import styled from "styled-components"
import { colors, breakpoints } from "./Style"

export const H1 = styled.h1`
  color: ${colors.blue};
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 1.15;

  @media ${breakpoints.medium} {
    font-size: 3.5rem;
  }

  @media ${breakpoints.large} {
    font-size: 4.2rem;
  }
`

export const H2 = styled.h2`
  color: ${colors.grey4};
  font-size: 2.5rem;
  line-height: 1.15;
  font-weight: 700;

  @media ${breakpoints.medium} {
    font-size: 2.8rem;
  }

  @media ${breakpoints.large} {
    font-size: 3.2rem;
  }
`

export const H3 = styled.h3`
  color: ${colors.grey4};
  font-size: 2rem;
  font-weight: 700;

  @media ${breakpoints.medium} {
    font-size: 2.5rem;
  }

  @media ${breakpoints.large} {
    font-size: 2.8rem;
  }
`

export const SubTitle = styled.p`
  font-size: 1.6rem;
  line-height: 1.8;

  @media ${breakpoints.medium} {
    font-size: 1.8rem;
  }
`

export const Intro = styled.p`
  font-size: 1.5rem;
  line-height: 1.75;
  font-weight: 700;

  @media ${breakpoints.medium} {
    font-size: 1.6rem;
  }
`

export const Text = styled.p`
  font-size: 1.4rem;
  line-height: 1.9;

  @media ${breakpoints.medium} {
    font-size: 1.5rem;
  }
`
