import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = () => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulAsset(filter: { title: { eq: "Player one" } }) {
          nodes {
            id
            title
            description
            sizes(quality: 100, maxWidth: 300) {
              ...GatsbyContentfulSizes
            }
          }
        }
      }
    `}
    render={data =>
      data.allContentfulAsset.nodes.map(({ id, title, description, sizes }) => (
        <Img key={id} fluid={sizes} alt={description} />
      ))
    }
  />
)
export default Image
