import React from "react"
import { useTransition } from "react-spring"

import { useStateValue } from "../../../state"

import Header from "../header"
import Body from "../body"
import { AnimatedBody, AnimatedBar } from "../Style"

export default function SidebarDesktop() {
  const [state] = useStateValue()

  const transitions = useTransition(state.sidebar.isOpen, null, {
    from: { x: 0 },
    enter: { x: 1 },
    leave: { x: 0 },
  })

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <AnimatedBar
          key={key}
          style={{
            transform: props.x.interpolate(x => `translateX(-${x * 100}%)`),
          }}
        >
          <Header />
          <AnimatedBody
            style={{
              opacity: props.x.interpolate(x => x),
            }}
            key={state.step.current}
          >
            <Body />
          </AnimatedBody>
        </AnimatedBar>
      )
  )
}
