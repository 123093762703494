import styled from "styled-components"

import { H3, Intro } from "../Text"
import { sidePadding } from "../Style"

export const Container = styled.div`
  padding: 3.2rem ${sidePadding};
`

export const Title = styled(H3)`
  margin-bottom: 1.1rem;
`

export const IntroBlock = styled(Intro)`
  margin-bottom: 2.9rem;
`

export const BtnContainer = styled.div`
  margin-top: 2rem;
`
