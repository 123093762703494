import * as THREE from "three"

// initiate Three.js clock for animations
const clock = new THREE.Clock()

export default function update(mixers) {
  const delta = clock.getDelta()

  mixers.forEach(mixer => {
    mixer.update(delta)
  })
}
