import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { useTransition } from "react-spring"

import { useStateValue } from "../../state"
import Modal from "../modal"
import { Content, Title, StyledIntro, Btn, ImgContainer } from "./Style"
import { Text } from "../Text"
import Image from "./Image"

export default function Startup() {
  const [state, dispatch] = useStateValue()

  const transitions = useTransition(state.step.current === 0, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <Modal
          key={key}
          style={props}
          display={state.step.current === 0}
          aria-labelledby="startup-title"
          aria-describedby="startup-description"
        >
          <ImgContainer>
            <Image />
          </ImgContainer>
          <StaticQuery
            query={graphql`
              query {
                allContentfulStartup {
                  nodes {
                    id
                    title
                    preamble {
                      preamble
                    }
                    text {
                      text
                    }
                    button_label
                  }
                }
              }
            `}
            render={data =>
              data.allContentfulStartup.nodes.map(
                ({ title, preamble, text, button_label, id }) => (
                  <Content key={id} as="div">
                    <Title id="startup-title">{title}</Title>
                    <div id="startup-description">
                      <StyledIntro>{preamble.preamble}</StyledIntro>
                      <Text>{text.text}</Text>
                    </div>
                    <Btn
                      icon={{
                        name: "arrow-right",
                        size: { width: 16, height: 16 },
                      }}
                      onClick={() => {
                        dispatch({
                          type: "STEP_NEXT",
                        })

                        dispatch({
                          type: "SIDEBAR_OPEN",
                        })
                      }}
                    >
                      {button_label}
                    </Btn>
                  </Content>
                )
              )
            }
          />
        </Modal>
      )
  )
}
