import React from "react"
import useMobile from "../../lib/useMobile"
import NavigationMobile from "./mobile"
import NavigationDesktop from "./desktop"

export default function Navigation() {
  const [isMobile] = useMobile()

  return isMobile ? <NavigationMobile /> : <NavigationDesktop />
}
