export const initialCanvas = {
  canvas: {
    road: null,
    trees: null,
    houses1: null,
    houses2: null,
    houses3: null,
    houses4: null,
    player: null,
    placeholder: null,
    isLoaded: false,
  },
}

export function canvasReducer(state, action) {
  switch (action.type) {
    case "CANVAS_LOADED":
      return { ...state, ...action.payload }
    default:
      return state
  }
}
