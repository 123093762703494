import React from "react"

import { useStateValue } from "../../../state"
import { Back, Next } from "../../Button"
import { Row, Column } from "./Style"

export default function NavigationDesktop() {
  const [state, dispatch] = useStateValue()
  const { current, content, length } = state.step

  return (
    <>
      <Row>
        {current > 1 && (
          <Column>
            <Back
              big
              iconSize={{ width: 20, height: 20 }}
              onClick={() => {
                dispatch({
                  type: "STEP_PREVIOUS",
                })

                dispatch({
                  type: "SIDEBAR_OPEN",
                })
              }}
            >
              {current - 1}.&nbsp;{content[current - 2].title}
            </Back>
          </Column>
        )}

        {current < length && (
          <Column>
            <Next
              big
              iconSize={{ width: 20, height: 20 }}
              onClick={() => {
                dispatch({
                  type: "STEP_NEXT",
                })

                dispatch({
                  type: "SIDEBAR_OPEN",
                })
              }}
            >
              {current + 1}.&nbsp;{content[current].title}
            </Next>
          </Column>
        )}
      </Row>
    </>
  )
}
