import React from 'react';
import { Image } from './Style';
import { useStaticQuery, graphql } from 'gatsby';

export default function Gif({ canvas }) {
	const data = useStaticQuery(graphql`
		query {
			allContentfulAsset(filter: { title: { eq: "Loader" } }) {
				nodes {
					fluid {
						base64
						aspectRatio
						src
						srcSet
						srcWebp
						srcSetWebp
						sizes
					}
				}
			}
		}
	`);

	return <Image fluid={data.allContentfulAsset.nodes[0].fluid} canvas={canvas} />;
}
