import styled from "styled-components"
import { animated } from "react-spring"

import { colors, zIndex, sidebarWidth, breakpoints } from "../Style"
import { hexToRgb } from "../../utils"

export const AnimatedBar = styled(animated.section)`
  position: fixed;
  top: 0;
  left: 100%;
  bottom: 0;
  z-index: ${zIndex.sidebar};
  width: ${sidebarWidth.medium};
  overflow-y: scroll;
  font-size: 2rem;
  background-color: rgba(${hexToRgb(colors.white).rgb}, 0.85);

  @media ${breakpoints.large} {
    width: ${sidebarWidth.large};
  }
`

export const AnimatedBody = styled(animated.div)``
