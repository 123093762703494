import styled from "styled-components"
import { animated } from "react-spring"
import { zIndex, colors, sidebarWidth, breakpoints } from "../Style"

export const Container = styled(animated.div)`
  @media ${breakpoints.medium} {
    display: flex;
    flex-flow: row nowrap;
    z-index: ${zIndex.progress};
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    width: calc(100% - ${sidebarWidth.medium});
    background-color: ${colors.white};
  }

  @media ${breakpoints.large} {
    width: calc(100% - ${sidebarWidth.large});
  }
`
