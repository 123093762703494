import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function Icon({
  prefix = "far",
  name,
  width,
  height,
  className,
}) {
  return (
    <FontAwesomeIcon
      className={className}
      icon={[prefix, name]}
      width={width}
      height={height}
    />
  )
}
