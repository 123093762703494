import styled from "styled-components"
import { animated } from "react-spring"

import { H2 } from "../../Text"
import Button, { Back } from "../../Button"
import { colors, sidePadding, breakpoints } from "../../Style"

export const Container = styled(animated.div)`
  padding: 3rem;

  @media ${breakpoints.medium} {
    padding: 5rem 4rem 3.8rem;
  }

  @media ${breakpoints.large} {
    padding: 6.4rem 5rem 3.8rem;
  }
`

export const LinkDetailContainer = styled(animated.div)`
  padding-top: 3.3rem;
`

export const Headline = styled(H2)`
  margin-bottom: 1.9rem;
`

export const Ul = styled.ul`
  margin-top: ${props => (props.spacing ? "2.2rem" : "4.2rem")};
`

export const Li = styled(animated.li)`
  border-top: 0.1rem solid ${colors.greyD};
  cursor: pointer;
`

export const ButtonBack = styled(Back)`
  margin-left: ${sidePadding};
`

export const ButtonReset = styled(Button)`
  margin-top: 2rem;
`
