import styled, { css } from "styled-components"
import Img from "gatsby-image"
import { colors, zIndex, breakpoints } from "../Style"

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${zIndex.loader};
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  background-color: ${colors.blue};
`

export const TextHelper = styled.p`
  color: ${colors.white};
  font-weight: 700;
  font-size: 2.2rem;
  margin: 0 auto auto;
  text-align: center;

  &:only-child {
    margin: auto;
  }
`

export const Small = styled.span`
  display: block;
  margin-top: 1rem;
  color: ${colors.white};
  font-size: 1.4rem;
  font-weight: 400;
`

export const Image = styled(Img)`
  width: ${40 * 0.5}rem;
  height: ${35 * 0.5}rem;
  margin: auto 0 0;

  ${props =>
    props.canvas &&
    css`
      position: absolute !important;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @media ${breakpoints.medium} {
        position: relative !important;
        top: auto;
        left: auto;
        margin: auto;
        transform: none;
      }
    `}
`
