import React from "react"
import styled from "styled-components"

import { colors, fontFamily, breakpoints } from "./Style"
import Icon from "./Icon"
import { hexToRgb } from "../utils/"

const BtnReset = styled.button`
  border: none;
  cursor: pointer;
  text-decoration: none;

  &:focus {
    box-shadow: none;
    outline: none;
  }
`

const Btn = styled(BtnReset)`
  min-width: 20rem;
  padding: 1.5rem 2rem 1.6rem;
  border-radius: 2.5rem;
  color: ${colors.white};
  font-family: ${fontFamily.inter};
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  background-color: ${props => (props.secondary ? colors.purple : colors.blue)};
  transition-property: background-color, color;
  transition-duration: 0.1s;
  transition-timing-function: ease-in-out;

  @media ${breakpoints.medium} {
    min-width: 30rem;
    font-size: 1.6rem;
  }

  &:focus,
  &:hover {
    color: ${colors.blue};
    background-color: ${colors.white};
  }

  &:not(:hover):focus {
    background-color: ${colors.blueFaded};
  }
`

const BtnWithIcon = styled(Btn)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  max-width: max-content;

  span {
    display: inline-block;
    ${props =>
      props.invert
        ? `padding-right: ${props.icon.size.width / 10 + 1}rem;`
        : `padding-left: ${props.icon.size.width / 10 + 1}rem;`}
    margin: auto;
  }

  svg {
    margin-left: 1rem;
  }
`

const TextBtn = styled(BtnReset)`
  display: inline-block;
  padding: 0;
  color: ${colors.blue};
  font-size: ${props => (props.big ? "1.8rem" : "1.5rem")};
  font-weight: 400;
  background-color: transparent;
  user-select: none;
  transition-property: box-shadow, color;
  transition-duration: 0.1s;
  transition-timing-function: ease-in-out;

  @media ${breakpoints.large} {
    font-size: ${props => (props.big ? "2rem" : "1.6rem")};
  }

  &:hover span {
    box-shadow: inset 0 -0.6rem 0 0 rgba(${hexToRgb(colors.blue).rgb}, 0.1);
  }

  &:focus {
    color: ${colors.purple};
  }

  &:hover:focus span {
    box-shadow: inset 0 -0.6rem 0 0 rgba(${hexToRgb(colors.purple).rgb}, 0.1);
  }
`

const TextIcon = styled(Icon)`
  position: relative;
  top: 0.2rem;

  &:first-child {
    margin-right: 0.3rem;
  }

  &:last-child {
    margin-left: 0.3rem;
  }
`

export default function Button({
  children,
  icon,
  className,
  invert = false,
  ...rest
}) {
  if (icon) {
    return (
      <BtnWithIcon
        icon={icon}
        className={className}
        invert={invert}
        {...rest}
        type="button"
      >
        {invert ? null : <span>{children}</span>}
        <Icon
          style={{ marginRight: 0 }}
          name={icon.name}
          width={icon.size.width}
          height={icon.size.height}
        />
        {invert && <span>{children}</span>}
      </BtnWithIcon>
    )
  }

  return (
    <Btn className={className} {...rest} type="button">
      {children}
    </Btn>
  )
}

export function TextButton({ children, className, ...rest }) {
  return (
    <TextBtn className={className} {...rest} type="button">
      {children}
    </TextBtn>
  )
}

export function Back({
  children,
  className,
  iconSize = { widht: 16, height: 16 },
  ...rest
}) {
  return (
    <TextBtn className={className} {...rest} type="button">
      <span>
        <TextIcon
          name="arrow-left"
          width={iconSize.width}
          height={iconSize.height}
        />
        {children ? children : "Tillbaka"}
      </span>
    </TextBtn>
  )
}

export function Next({
  children,
  className,
  iconSize = { widht: 16, height: 16 },
  ...rest
}) {
  return (
    <TextBtn className={className} {...rest} type="button">
      <span>
        {children ? children : "Nästa"}
        <TextIcon
          name="arrow-right"
          width={iconSize.width}
          height={iconSize.height}
        />
      </span>
    </TextBtn>
  )
}
