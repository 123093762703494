import styled from "styled-components"
import { animated } from "react-spring"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { zIndex, colors, breakpoints } from "../Style"

export const Text = styled(animated.p)`
  position: absolute;
  top: 1rem;
  left: 1.5rem;
  z-index: ${zIndex.footer};
  color: ${colors.white};
  font-size: 1.4rem;
  font-weight: 500;
  text-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.2);

  @media ${breakpoints.medium} {
    position: fixed;
    top: auto;
    bottom: 2.2rem;
    left: 2.5rem;
    font-size: 1.6rem;
  }
`

export const Icon = styled(FontAwesomeIcon)`
  position: relative;
  top: 0.4rem;
  margin-right: 1rem;
`
