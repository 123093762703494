import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { FixObject } from "../../utils/index"

const loader = new GLTFLoader()
let isCalling = []

function fetchGlft({ id, path, resolve, reject }) {
  if (!isCalling[path]) {
    loader.load(
      path,
      gltf => {
        // return promise
        gltf.scene.name = id
        gltf.scene.traverse(function(node) {
          FixObject(node, { metalness: true, shadows: true, hidePlayer: true })
        })
        resolve({ data: gltf })
        // store data in localstorage for faster load next time
        try {
          localStorage.setItem(`reut-${id}`, JSON.stringify(gltf.parser.json))
        } catch (e) {
          throw new Error(`Could not store scene JSON: ${e}`)
        }
        // clean token
        isCalling[path] = false
      },
      xhr => {
        // called while loading is progressing
        isCalling[path] = true
      },
      error => {
        isCalling[path] = false
        // called when loading has errors
        reject(new Error(`Could not load the following 3D asset: "${path}"`))
      }
    )
  }
}

export default function CanvasLoader(id, path) {
  return new Promise((resolve, reject) => {
    const storedJSON = localStorage.getItem(`reut-${id}`)

    if (storedJSON) {
      const getFromStorage = new Promise((res, rej) => {
        try {
          loader.parse(storedJSON, null, gltf => {
            gltf.scene.name = id
            gltf.scene.traverse(function(node) {
              FixObject(node, {
                metalness: true,
                shadows: true,
                hidePlayer: true,
              })
            })
            res({ data: gltf })
          })
        } catch (e) {
          rej(new Error(`Could not parse stored JSON with id: ${id}, ${e}`))
        }
      })

      getFromStorage
        .then(({ data }) => {
          resolve({ data })
        })
        .catch(e => {
          fetchGlft({ id, path, resolve, reject })
        })
    } else {
      fetchGlft({ id, path, resolve, reject })
    }
  })
}
