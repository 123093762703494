import styled from "styled-components"
import { colors, breakpoints, zIndex } from "../Style"

export const Container = styled.div`
  position: absolute;
  left: 50%;
  top: calc(70% - 3.5rem);
  z-index: ${zIndex.progress};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  transform: translateX(-50%);

  @media ${breakpoints.medium} {
    top: -3.5rem;
  }

  @media ${breakpoints.large} {
    position: static;
    padding: 1.8rem 2rem;
    background-color: ${colors.blue};
    transform: none;
  }
`
export const DotWrapper = styled.label`
  position: relative;
  z-index: 0;

  &:not(:last-child) {
    margin-right: 1.4rem;
  }
`

export const Dot = styled.span`
  display: block;
  height: 1rem;
  width: 1rem;
  border: 0.2rem solid ${colors.white};
  border-radius: 50%;
  background-color: ${props => (props.active ? colors.white : "transparent")};
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;

  @media ${breakpoints.medium} {
    height: 1.5rem;
    width: 1.5rem;
  }

  @media ${breakpoints.large} {
    height: 2.2rem;
    width: 2.2rem;
  }

  /* inner circle effect */
  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 0;
    height: 0;
    opacity: 0;
    border-radius: 50%;
    background-color: ${props => (props.active ? colors.purple : colors.white)};
    transform: translate(-50%, -50%);
    content: "";
    transition-property: width, height, opacity;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  &:hover {
    outline: none;
    background-color: transparent;

    &:before {
      width: 1.2rem;
      height: 1.2rem;
      opacity: 1;

      @media ${breakpoints.medium} {
        height: 0.75rem;
        width: 0.75rem;
      }

      @media ${breakpoints.large} {
        width: 1.2rem;
        height: 1.2rem;
      }
    }
  }
`

export const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  display: block;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
  appearance: none;

  &:focus ~ span {
    outline: none;
    background-color: transparent;

    &:before {
      width: 1.2rem;
      height: 1.2rem;
      opacity: 1;
    }
  }
`
