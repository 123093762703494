import React from "react"
import { Container, Title, IntroBlock, BtnContainer } from "./Style"
import { Text } from "../Text"
import Button from "../Button"
import { Try } from "../../utils"

export default function LinkDetail({
  title = false,
  preamble = false,
  text = false,
  buttonText = null,
  buttonHref = false,
}) {
  return (
    <Container>
      {title && <Title>{title}</Title>}
      {preamble && <IntroBlock>{Try(preamble, 'preamble')}</IntroBlock>}
      {text && <Text>{Try(text, 'text')}</Text>}
      <BtnContainer>
        <Button
          as="a"
          target="_blank"
          icon={{ name: "external-link", size: { width: 21, height: 21 } }}
          secondary
          href={buttonHref}
        >
          {buttonText ? buttonText : 'Till länken'}
        </Button>
      </BtnContainer>
    </Container>
  )
}
