import styled from "styled-components"
import { animated } from "react-spring"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { colors, zIndex } from "../../Style"

export const AnimatedButton = styled(animated.button)`
  position: fixed;
  z-index: ${zIndex.toggle};
  right: 2.5rem;
  top: 2.5rem;
  border: none;
  padding: 0;
  margin: 0;
  color: ${colors.white};
  background-color: transparent;
  cursor: pointer;
  outline: none;

  &,
  svg {
    width: 3.5rem;
    height: 3.5rem;
  }
`
export const Icon = styled(FontAwesomeIcon)``
