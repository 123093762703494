import React, { useEffect, useRef } from "react"
import { Container, Column, Btn } from "./Style"
import { useStateValue } from "../../../state"

export default function NavigationMobile() {
  const [state, dispatch] = useStateValue()
  const mount = useRef(null)
  const { current, length } = state.step

  useEffect(() => {
    const handleResize = () => {
      document.body.style.paddingBottom = `${
        mount.current.getBoundingClientRect().height
      }px`
    }

    handleResize()
    window.addEventListener("resize", handleResize)

    return () => {
      document.body.style.paddingBottom = null
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <Container ref={mount}>
      <Column>
        {current > 1 && (
          <Btn
            icon={{
              name: "arrow-left",
              size: { width: 16, height: 16 },
            }}
            invert={true}
            onClick={() => dispatch({ type: "STEP_PREVIOUS" })}
          >
            Föregående
          </Btn>
        )}
      </Column>
      <Column>
        {current < length + 1 && (
          <Btn
            icon={{
              name: "arrow-right",
              size: { width: 16, height: 16 },
            }}
            onClick={() => dispatch({ type: "STEP_NEXT" })}
          >
            Nästa
          </Btn>
        )}
      </Column>
    </Container>
  )
}
