import { config, library } from "@fortawesome/fontawesome-svg-core"
import {
  faBars,
  faArrowRight,
  faArrowLeft,
  faInfoCircle,
  faExternalLink,
} from "@fortawesome/pro-regular-svg-icons"
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons"

config.autoAddCss = false
library.add(
  faBars,
  faArrowRight,
  faInfoCircle,
  faChevronRight,
  faExternalLink,
  faArrowLeft
)
