import React from "react"
import Body from "../body"
import { Wrapper } from "./Style"
import Header from "../header"

export default function SidebarMobile() {
  return (
    <Wrapper>
      <Header />
      <Body />
    </Wrapper>
  )
}
