import styled from "styled-components"
import { zIndex, breakpoints, colors } from "../Style"

export const CanvasSize = styled.div`
  position: relative;
  width: 100vw;
  height: 70vh;

  @media ${breakpoints.medium} {
    height: 100vh;
  }
`

export const Canvas = styled(CanvasSize)`
  z-index: ${zIndex.canvas};
  pointer-events: none;

  @media ${breakpoints.medium} {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`

export const Container = styled(CanvasSize)`
  display: flex;
  background-color: ${colors.blue};
  pointer-events: none;
`
