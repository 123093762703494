import React from "react"
import { useSpring } from "react-spring"

import { useStateValue } from "../../state"
import { Text, Icon } from "./Style"

export default function Footer() {
  const [state] = useStateValue()

  const props = useSpring({
    opacity: state.step.current > 0 ? 0 : 1,
    from: { opacity: 0 },
  })

  return (
    <Text style={props} aria-hidden={state.step.current > 0}>
      <Icon icon={["far", "info-circle"]} width={20} height={20} />
      En webb från Region Örebro Län
    </Text>
  )
}
