import styled from "styled-components"
import Button from "../Button"
import { H1, Intro, Text } from "../Text"

export const Content = styled(Text)`
  max-width: 64rem;
  margin: 11.6rem auto 0;
`

export const Title = styled(H1)`
  margin-bottom: 1.2rem;
`

export const StyledIntro = styled(Intro)`
  margin-bottom: 1.6rem;
`

export const Btn = styled(Button)`
  margin: 3.7rem auto 0;
  text-transform: uppercase;
`

export const ImgContainer = styled.div`
  position: absolute;
  bottom: calc(100% - 10rem);
  left: 50%;
  width: 35vw;
  max-width: 26.8rem;
  max-height: 34.8rem;
  transform: translateX(-50%);
`
