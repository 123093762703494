import React from "react"
import styled, { keyframes } from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

// get a random number
// random is outside component so it doesn't change
const random = Math.random()

const blink = keyframes`
  50% {color: transparent;}
`
export const Dot = styled.span`
  animation: 1s ${blink} infinite;
  animation-timing-function: ease-in-out;

  &:nth-child(1) {
    animation-delay: 0ms;
  }
  &:nth-child(2) {
    animation-delay: 250ms;
  }
  &:nth-child(3) {
    animation-delay: 500ms;
  }
`

export function Text() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulLoadingPlaceholder {
        nodes {
          text
        }
        totalCount
      }
    }
  `)

  return (
    <>
      {
        data.allContentfulLoadingPlaceholder.nodes[
          Math.floor(random * data.allContentfulLoadingPlaceholder.totalCount)
        ].text
      }
      <Dot>.</Dot>
      <Dot>.</Dot>
      <Dot>.</Dot>
    </>
  )
}
