import styled from "styled-components"
import Icon from "../Icon"
import { colors, sidePadding } from "../Style"
import { hexToRgb } from "../../utils"

export const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  color: inherit;
  text-decoration: none;

  &:focus {
    outline: none;
  }
`

export const Content = styled.div`
  flex: 1;
  padding: ${props =>
    props.intro ? `2.3rem ${sidePadding} 2rem` : `3rem ${sidePadding} 2rem`};
`

export const Title = styled.h3`
  display: inline-block;
  margin-bottom: 0.9rem;
  color: ${colors.grey4};
  font-size: 2rem;
  font-weight: 700;
  transition-property: box-shadow, color;
  transition-duration: 0.1s;
  transition-timing-function: ease-in-out;

  ${Container}:hover & {
    color: ${colors.blue};

    span {
      box-shadow: inset 0 -0.6rem 0 0 rgba(${hexToRgb(colors.blue).rgb}, 0.1);
    }
  }

  ${Container}:focus & {
    color: ${colors.purple};
  }

  ${Container}:hover:focus & span {
    box-shadow: inset 0 -0.6rem 0 0 rgba(${hexToRgb(colors.purple).rgb}, 0.1);
  }
`

export const Intro = styled.p`
  font-size: 1.5rem;
  line-height: 1.9;
`

export const SideBar = styled.div`
  display: flex;
  align-items: center;
  width: 5rem;
  margin-right: 0;
  background-color: ${props =>
    props.highlight ? colors.purple : colors.greyC};
  transition: background-color 0.1s ease-in-out;

  ${Container}:hover & {
    background-color: ${colors.blue};
  }

  ${Container}:hover:focus &,
  ${Container}:focus & {
    background-color: ${props =>
      props.highlight ? colors.purple : colors.greyC};
  }
`

export const StyledIcon = styled(Icon)`
  margin: auto;
  color: ${colors.white};
`
