export const initialStep = {
  step: {
    current: 0,
    content: [],
    length: 0,
    previous: 0,
  },
}

export function stepReducer(state, action) {
  switch (action.type) {
    case "STEP_NEXT":
      return {
        ...state,
        previous: state.current,
        current: state.current + 1,
      }
    case "STEP_PREVIOUS":
      return {
        ...state,
        previous: state.current,
        current: state.current - 1,
      }
    case "STEP_RESET":
      return {
        ...state,
        previous: state.current,
        current: 1,
      }
    case "STEP_SET":
      return {
        ...state,
        previous: state.current,
        ...action.payload,
      }
    default:
      return state
  }
}
