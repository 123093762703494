import * as THREE from 'three'

export default function setAnimation(object, animations) {
  const mixer = new THREE.AnimationMixer(object)

  const action = mixer.clipAction(animations[0])
  action.play()

  return mixer
}
