import React from "react"
import SidebarDesktop from "./desktop/"
import SidebarMobile from "./mobile/"
import useMobile from "../../lib/useMobile"

export default function Sidebar() {
  const [isMobile] = useMobile()

  return isMobile ? <SidebarMobile /> : <SidebarDesktop />
}
