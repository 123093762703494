import React, { useEffect, useCallback } from "react"
import { useTransition } from "react-spring"

import { useStateValue } from "../../state"
import useMobile from "../../lib/useMobile"

import Indicators from "../indicators/"
import Navigation from "../navigation/"
import { Container } from "./Style"

export default function ProgressDesktop() {
  const [isMobile] = useMobile()
  const [state, dispatch] = useStateValue()

  const transitions = useTransition(state.step.current > 0, null, {
    from: { transform: "translate(0, 100%)" },
    enter: { transform: "translate(0, 0)" },
    leave: { transform: "translate(0, 100%)" },
  })

  const handleKeyboard = useCallback(
    event => {
      switch (event.key) {
        case "ArrowLeft":
          if (state.step.current > 0) {
            dispatch({ type: "STEP_PREVIOUS" })
          }
          break
        case "ArrowRight":
          if (state.step.current < state.step.length) {
            dispatch({ type: "STEP_NEXT" })
          }
          break
        default:
          break
      }
    },
    [dispatch, state.step]
  )

  useEffect(() => {
    document.addEventListener("keydown", handleKeyboard)

    return () => {
      document.removeEventListener("keydown", handleKeyboard)
    }
  }, [handleKeyboard])

  const handleContent = (key = null, props = null) => (
    <Container key={key} aria-hidden={state.step.current < 1} style={props}>
      <Indicators />
      <Navigation />
    </Container>
  )

  // Only have the transition on desktop
  return isMobile
    ? handleContent()
    : transitions.map(({ item, key, props }) =>
        item ? handleContent(key, props) : null
      )
}
