import styled from "styled-components"
import { colors, breakpoints } from "../../Style"

export const Wrapper = styled.div`
  background-color: ${colors.white};

  @media ${breakpoints.medium} {
    background-color: transparent;
  }
`
