import React from "react"
import { StateProvider } from "../state/"
import {
  initialStep,
  stepReducer,
  initialSidebar,
  sidebarReducer,
  initialCanvas,
  canvasReducer,
} from "../reducers"

import App from "../components/App"
import SEO from "../components/seo"

const IndexPage = () => {
  // state management
  const initialState = {
    ...initialStep,
    ...initialSidebar,
    ...initialCanvas,
  }
  // main reducer
  const mainReducer = ({ step, sidebar, canvas }, action) => ({
    step: stepReducer(step, action),
    sidebar: sidebarReducer(sidebar, action),
    canvas: canvasReducer(canvas, action),
  })

  // add context and reducers to the whole app
  return (
 
<StateProvider initialState={initialState} reducer={mainReducer}>
  <SEO title="Home" />
  <App>
</App>
</StateProvider>
  )
}

export default IndexPage
