import React from 'react';
import Gif from './Gif';
import { Text } from './Text';
import { Container, TextHelper, Small } from './Style';

export default function Loading() {
	return (
		<Container>
			<Gif />
			<TextHelper>
				<Text />
				<Small>Laddar</Small>
			</TextHelper>
		</Container>
	);
}
