import React, { useRef } from "react"
import PropTypes from "prop-types"
import { useTransition } from "react-spring"
import { Overlay, Content } from "./Style"
import useFocusTrap from "../../lib/useFocusTrap"

export default function Modal({ children, display = true, ...rest }) {
  const modalRef = useRef(null)

  const transitions = useTransition(display, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  useFocusTrap(modalRef, { shouldTrap: display })

  return transitions.map(({ item, key, props }) =>
    item ? (
      <Overlay
        key={key}
        style={props}
        display={display ? 1 : 0}
        role="dialog"
        {...rest}
      >
        <Content ref={modalRef}>{children}</Content>
      </Overlay>
    ) : null
  )
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
}
