import React from "react"
import { useStateValue } from "../../../state"
import { Container, Headline, Close } from "./Style"
import useMobile from "../../../lib/useMobile"

export default function Header() {
  const [, dispatch] = useStateValue()
  const [isMobile] = useMobile()

  return (
    <Container>
      {!isMobile && (
        <Close
          onClick={() =>
            dispatch({
              type: "SIDEBAR_CLOSE",
            })
          }
        />
      )}

      <Headline>Företagsresan</Headline>
    </Container>
  )
}
