import { useEffect, useState } from "react"
import * as THREE from "three"
const OrbitControls = require("three-orbit-controls")(THREE)

export default function useControls(camera, renderer) {
  // should sor!t the cameras in a better way, but I have no time
  const [controls, setControls] = useState(null)

  useEffect(() => {
    // set controls once camera is initiated
    if (camera && !controls) {
      const ctrl = new OrbitControls(camera, renderer.domElement)
      setControls(ctrl)
    }
  }, [camera])

  // manage controls
  useEffect(() => {
    if (controls) {
      controls.enableRotate = false
      controls.enableDamping = true
      controls.dampingFactor = 0.5
      controls.enableZoom = false
      controls.enablePan = false
      controls.enableKeys = false
      // controls.minDistance = 700
      // controls.maxDistance = 7000
      controls.maxPolarAngle = Math.PI / 2.5
      controls.autoRotateSpeed = 0.1
      controls.autoRotate = true
      controls.update()

      return () => {
        if (controls) {
          controls.dispose()
        }
      }
    }
  }, [controls])

  return [controls, setControls]
}
