import React from "react"
import PropTypes from "prop-types"
import { Container, Content, Title, Intro, SideBar, StyledIcon } from "./Style"

export default function Link({
  title,
  intro = false,
  highlight = true,
  empty = false,
  id,
  ...rest
}) {
  return (
    <Container
      tabIndex="0"
      aria-labelledby={`link-title-${id}`}
      aria-describedby={`link-description-${id}`}
      {...rest}
    >
      <Content intro={intro}>
        <Title id={`link-title-${id}`}>
          <span>{title}</span>
        </Title>
        {intro ? <Intro id={`link-description-${id}`}>{intro}</Intro> : null}
      </Content>
      {!empty ? (
        <SideBar highlight={highlight}>
          <StyledIcon prefix="fa" name="chevron-right" width={9} height={15} />
        </SideBar>
      ) : null}
    </Container>
  )
}

Link.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  intro: PropTypes.string,
  highlight: PropTypes.bool,
  empty: PropTypes.bool,
}
