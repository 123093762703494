import React, { useRef, useEffect, useState } from "react"
import * as THREE from "three"
import { useStateValue } from "../../state"
import { Container, Canvas } from "./Style"
import Gif from "../loading/Gif"
import setLights from "./Lights"
import useCamera from "./Camera"
import useControls from "./Controls"
import setAnimation from "./Animation"
import update from "./Clock"
import { FixObject, inRange, hideObjs, showObjs, isVisible } from "../../utils"
import useMobile from "../../lib/useMobile"

let mixers = []

export default function Scene() {
  const [isMobile] = useMobile()
  const [state] = useStateValue()
  const [scene] = useState(new THREE.Scene())
  const [renderer] = useState(
    new THREE.WebGLRenderer({
      alpha: true,
      antialias: true,
      powerPreference: "high-performance",
    })
  )
  const mount = useRef(null)
  const animRequestRef = useRef()
  const playerFadeRef = useRef()
  const slideRef = useRef()
  const cameraMoveRef = useRef()
  const cameraDelayRef = useRef()
  const [camera, , target, pov, mobileTarget] = useCamera(mount)
  const [controls] = useControls(camera, renderer)
  const [added, setAdded] = useState({
    road: false,
    trees: false,
    player: false,
    houses1: false,
    houses2: false,
    houses3: false,
    houses4: false,
    placeholder: false,
  })

  const [playerObjects, setPlayerObjects] = useState(null)
  const [arrowObjects, setArrowObjects] = useState(null)
  const [playerOrigins, setPlayerOrigins] = useState()
  const config = {
    speed: 0.8,
    deltaOrigin: {
      arrival: 20,
      departure: 5,
    },
  }
  const axis = ["x", "x", "z", "x", "x", "z"]
  const dir = [1, 1, 1, -1, -1, -1]

  const { current, previous, length } = state.step
  const {
    road,
    trees,
    player,
    houses1,
    houses2,
    houses3,
    houses4,
    placeholder,
  } = state.canvas

  const looper = {
    road,
    trees,
    player,
    placeholder,
    houses1,
    houses2,
    houses3,
    houses4,
  }

  const renderScene = () => {
    controls && controls.update()
    camera && renderer.render(scene, camera)
  }

  const animate = time => {
    update(mixers)
    renderScene()
    animRequestRef.current = requestAnimationFrame(animate)
  }

  const handleAnimations = (obj, arr) => {
    // add to the mixers (needed for animation)
    if (arr.length > 0) {
      mixers.push(setAnimation(obj, arr))
    }
  }

  const showPlayer = step => {
    if (step !== length) {
      showObjs(playerObjects[step], arrowObjects[step])

      // get all meshes of both the scooter man and the boucing arrow
      const meshes = [
        ...playerObjects[step].children,
        arrowObjects[step].children,
      ].filter(obj => obj.type === "Mesh")

      // fade in meshes
      meshes.forEach(mesh => {
        playerFadeRef.current = requestAnimationFrame(() => {
          fadeInOpacity(mesh)
        })
      })

      slideIn(playerObjects[step], step)
    }
  }

  const fadeInOpacity = mesh => {
    if (mesh) {
      // chech if fade in is done
      if (mesh.material.opacity >= 1) {
        // fade in is completed
        mesh.material.opacity = 1
        mesh.material.transparent = false
        cancelAnimationFrame(playerFadeRef.current)
      } else {
        mesh.material.transparent = true
        mesh.material.opacity = mesh.material.opacity + 0.05
        // fade in more
        playerFadeRef.current = requestAnimationFrame(() => {
          fadeInOpacity(mesh)
        })
      }
    }
  }

  const fadeOutOpacity = (mesh, callback = false) => {
    if (mesh) {
      // check if fade out is completetd
      if (mesh.material.opacity <= 0) {
        // fade out is completed
        mesh.material.transparent = true
        mesh.material.opacity = 0
        // stop fadeOut
        cancelAnimationFrame(playerFadeRef.current)
        cancelAnimationFrame(slideRef.current)
        // if there are any callback, call now
        if (callback !== false) {
          callback()
        }
      } else {
        mesh.material.transparent = true
        mesh.material.opacity = mesh.material.opacity - 0.05
        // fade out more
        playerFadeRef.current = requestAnimationFrame(() => {
          fadeOutOpacity(mesh, callback)
        })
      }
    }
  }

  const hidePlayer = step => {
    if (step !== length) {
      slideOut(playerObjects[step], step)

      // get all meshes of both the scooter man and the boucing arrow
      const meshes = [
        ...playerObjects[step].children,
        ...arrowObjects[step].children,
      ].filter(obj => obj.type === "Mesh")

      // fade in meshes
      meshes.forEach(mesh => {
        playerFadeRef.current = requestAnimationFrame(() => {
          fadeOutOpacity(mesh, () =>
            hideObjs(playerObjects[step], arrowObjects[step])
          )
        })
      })
    }
  }

  const slideIn = (obj, step) => {
    const thisAxis = axis[step]
    const thisDir = dir[step]
    // put player to arrival position
    obj.position[thisAxis] =
      playerOrigins[step][thisAxis] - config.deltaOrigin.arrival * thisDir

    const move = () => {
      // move player little by little
      obj.position[thisAxis] = obj.position[thisAxis] + config.speed * thisDir

      // check if player has reached goal
      if (
        (thisDir > 0 &&
          obj.position[thisAxis] >= playerOrigins[step][thisAxis]) ||
        (thisDir < 0 && obj.position[thisAxis] <= playerOrigins[step][thisAxis])
      ) {
        // reached goal? stop moving
        cancelAnimationFrame(slideRef.current)
      } else {
        // hasn't reached goal? continue moving
        slideRef.current = requestAnimationFrame(move)
      }
    }

    // start moving
    slideRef.current = requestAnimationFrame(move)
  }

  const slideOut = (obj, step) => {
    const thisAxis = axis[step]
    const thisDir = dir[step]

    const move = () => {
      obj.position[thisAxis] = obj.position[thisAxis] + config.speed * thisDir

      if (
        (thisDir > 0 &&
          obj.position[thisAxis] >=
            playerOrigins[step][thisAxis] +
              config.deltaOrigin.departure * thisDir) ||
        (thisDir < 0 &&
          obj.position[thisAxis] <=
            playerOrigins[step][thisAxis] +
              config.deltaOrigin.departure * thisDir)
      ) {
        cancelAnimationFrame(slideRef.current)
      } else {
        slideRef.current = requestAnimationFrame(move)
      }
      slideRef.current = requestAnimationFrame(move)
    }

    slideRef.current = requestAnimationFrame(move)
  }

  useEffect(() => {
    const { current: element } = mount

    // set renderer size once component is mounted
    renderer.setPixelRatio(window.devicePixelRatio)
    renderer.setSize(element.clientWidth, element.clientHeight)

    // set light
    setLights({ scene, renderer })

    const handleResize = () => {
      renderer.setSize(element.clientWidth, element.clientHeight)
    }

    element.appendChild(renderer.domElement)
    window.addEventListener("resize", handleResize)

    return () => {
      element.removeChild(renderer.domElement)
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    // Once the scenes are loaded, add them to the scene
    for (let [key, value] of Object.entries(looper)) {
      if (value && !added[key]) {
        // get scene and animation
        const { scene: s, animations = [] } = value
        // handle animations if there're any
        handleAnimations(s, animations)
        // add the model to the scene
        scene.add(s)
        // keep track that it has been added
        setAdded({ ...added, [key]: true })
      }
    }

    // once everything is loaded, remove placeholder
    if (
      added.placeholder &&
      added.houses1 &&
      added.houses2 &&
      added.houses3 &&
      added.houses4
    ) {
      scene.remove(looper.placeholder.scene)
    }
  }, [state.canvas, added])

  // animate scene
  useEffect(() => {
    animRequestRef.current = requestAnimationFrame(animate)
    return () => cancelAnimationFrame(animRequestRef.current)
  }, [state.canvas, camera])

  useEffect(() => {
    // set camera and controls to initial step once initiated
    if (controls && camera) {
      // where the camera should point at
      const { x: px, y: py, z: pz } = target[0]
      // where the camera position should start before rotating
      const { x: cx, y: cy, z: cz } = pov[0]
      // set camera position
      camera.position.set(cx, cy, cz)
      // set controls position
      controls.target.set(px, py, pz)
      // update controls
      controls.update()
    }
  }, [controls, camera])

  useEffect(() => {
    // get "moveOut" camera position (overview from above, the in-between-steps view)
    let { x: moveOutCamX, y: moveOutCamY, z: moveOutCamZ } = pov[6]
    // moveOut vector
    const moveOutCamPos = new THREE.Vector3(
      moveOutCamX,
      moveOutCamY,
      moveOutCamZ
    )
    // where the camera should go to, "move in" position
    const { x: moveInX, y: moveInY, z: moveInZ } = pov[current]
    // moveIn vector
    const moveInPos = new THREE.Vector3(moveInX, moveInY, moveInZ)

    // get position of target (man at station)
    const { x: px, y: py, z: pz } =
      isMobile && current === length ? mobileTarget : target[current]
    // target position vector
    const controlsPos = new THREE.Vector3(px, py, pz)
    // get target (look at) of in betweeen camera
    const { x: moveOutTargetX, y: moveOutTargetY, z: moveOutTargetZ } = isMobile
      ? mobileTarget
      : target[length]
    // in beween scene vector
    const moveOutTargetPos = new THREE.Vector3(
      moveOutTargetX,
      moveOutTargetY,
      moveOutTargetZ
    )

    const moveOut = () => {
      // get camera position
      const { x, y, z } = camera.position
      // interpolate current camera position to in-between camera position
      const cameraPos = new THREE.Vector3(x, y, z)
      cameraPos.lerp(moveOutCamPos, 0.05)
      // position camera closer to target
      camera.position.copy(cameraPos)
      // focus on correct target
      controls.target.lerp(moveOutTargetPos, 0.05)
      // update controls as soon as they get changed
      controls.update()

      // repeat until camera is at target (or at least close enough - it will never be at target because of lerp functiton)
      if (inRange(x, moveOutCamX, 50)) {
        // stop moving the camera once it has reached desired location
        cancelAnimationFrame(cameraMoveRef.current)
        // don't move in for last step to avoid harsh move
        if (current !== length) {
          cameraDelayRef.current = setTimeout(() => {
            // move camera to next station after x milliseconds
            cameraMoveRef.current = requestAnimationFrame(moveIn)
            // clear reference of setTimeout
            cameraDelayRef.current = null
          }, 500)
        }

        // hide player
        hidePlayer(previous)
      } else {
        // continue moving camera
        cameraMoveRef.current = requestAnimationFrame(moveOut)
      }
    }

    const moveIn = () => {
      // get camera position (should be at overview on start)
      const { x, y, z } = camera.position
      // camera position vector
      const cameraPos = new THREE.Vector3(x, y, z)
      // interpolate controls
      controls.target.lerp(controlsPos, 0.05)
      // update controls as soon as they get changed
      controls.update()
      // interpolate values
      cameraPos.lerp(moveInPos, 0.05)
      // copy settings to camera == 'move' camera
      camera.position.copy(cameraPos)

      if (inRange(x, moveInX, 10)) {
        // stop moving the camera once it has reached desired location
        cancelAnimationFrame(cameraMoveRef.current)
        // start rotation again
        controls.autoRotate = true
        // update controls
        controls.update()
        // show player
        showPlayer(current)
      } else {
        // continue moving camera
        cameraMoveRef.current = requestAnimationFrame(moveIn)
      }
    }

    if (controls && current > 0) {
      // stop autorotate when we leave startup screen
      if (controls.autoRotate) {
        controls.autoRotate = false
        controls.update()
      }
    }

    if (camera && current > 0) {
      cameraMoveRef.current = requestAnimationFrame(moveOut)

      return () => {
        // stop setTimeout for moveOut
        if (cameraDelayRef) {
          // clear timeout if there are any
          clearTimeout(cameraDelayRef)
          // clear ref
          cameraDelayRef.current = null
        }
        // stop animation of camera
        cancelAnimationFrame(cameraMoveRef.current)
      }
    }
  }, [current, previous])

  useEffect(() => {
    // check if the Player Object has been donwloaded
    if (player && !playerObjects) {
      // keep the player objects in a state; objects are the arrrow and the scooterr man
      setPlayerObjects(
        player.scene.children[0].children.filter(obj =>
          obj.name.includes("man_step")
        )
      )
    }

    if (player && !arrowObjects) {
      setArrowObjects(
        player.scene.children[0].children.filter(obj =>
          obj.name.includes("Pilen")
        )
      )
    }

    if (playerObjects) {
      // keep track of their origin for later animations
      setPlayerOrigins(
        playerObjects.map(obj => {
          // store location point (x,y,z)
          return { x: obj.position.x, y: obj.position.y, z: obj.position.z }
        })
      )
    }
  }, [player, playerObjects, arrowObjects])

  return (
    <Container>
      <Gif canvas={true} />
      <Canvas ref={mount} />
    </Container>
  )
}
