export const initialSidebar = {
  sidebar: { isOpen: false },
}

export function sidebarReducer(state, action) {
  switch (action.type) {
    case 'SIDEBAR_OPEN':
      return {
        ...state,
        isOpen: true,
      }
    case 'SIDEBAR_CLOSE':
      return {
        ...state,
        isOpen: false,
      }
    case 'SIDEBAR_TOGGLE':
      return {
        ...state,
        isOpen: !state.isOpen,
      }
    default:
      return state
  }
}
