import hasKey from "./HasKey"

/* Due to Contentful JSON structure,
 * I check if the key exists (could be null) before trying
 * to retrieve ob[key][key] when field is not required
 * */

export default (obj, key) => {
  return hasKey(obj, key)
    ? hasKey(obj[key], key)
      ? obj[key][key]
      : obj[key]
    : null
}
