import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { useTransition, animated } from "react-spring"

import { GlobalStyle } from "./Style"
import { useStateValue } from "../state"
import "../lib/iconLibrary"

import Loading from "./loading"
import Startup from "./startup"
import Canvas from "./canvas"
import CanvasLoader from "./canvas/Loader"
import SidebarTrigger from "./sidebar-trigger"
import Sidebar from "./sidebar/"
import Progress from "./progress"
import Footer from "./footer"

export default function App({ children }) {
  const [state, dispatch] = useStateValue()
  const [loading, setLoading] = useState(true)

  const transitions = useTransition(loading, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  const q = useStaticQuery(graphql`
    query {
      roadObject: allFile(filter: {relativeDirectory: {regex: "/city/"}, name: {eq: "road"}}) {
        nodes {
          publicURL
          name
        }
      }

      otherObjects: allFile(filter: {relativeDirectory: {regex: "/city/"}, name: {ne: "road"}}) {
        nodes {
          publicURL
          name
        }
      }

      allContentfulStep {
        totalCount
        nodes {
          title
          step_id
          description {
            description
          }
          categories {
            links {
              title
              text {
                text
              }
              preamble {
                preamble
              }
              button_label
              intro
              highlight
              url
            }
            title
          }
        }
      }
    }
  `)

  useEffect(
    () =>
      dispatch({
        type: "STEP_SET",
        payload: {
          content: q.allContentfulStep.nodes.sort(
            (a, b) => a.step_id - b.step_id
          ),
          length: q.allContentfulStep.totalCount,
        },
      }),
    []
  )

  useEffect(() => {
    CanvasLoader(
      "road",
      q.roadObject.nodes[0].publicURL
    ).then(({ data }) => {
      dispatch({
        type: "CANVAS_LOADED",
        payload: {
          road: data,
          isLoaded: true,
          cameras: data.cameras,
        },
      })
    })
  }, [dispatch])

  useEffect(() => {
    if (state.canvas.isLoaded) {
      setLoading(false)
      // load extra elements on the map
      q.otherObjects.nodes
        .forEach(node => {
          CanvasLoader(node.name, node.publicURL).then(({ data }) => {
            dispatch({
              type: "CANVAS_LOADED",
              payload: {
                [node.name]: data,
              },
            })
          })
        })
    }
  }, [state.canvas.isLoaded])

  return (
    <>
      <GlobalStyle />

      {transitions.map(({ item, key, props }) =>
        !item ? (
          <animated.div key={key} style={props}>
            <Startup />
            <Canvas />
            <SidebarTrigger />
            <Sidebar />
            <Progress />
            <Footer />
          </animated.div>
        ) : (
          <animated.div key={key} style={props}>
            <Loading />
          </animated.div>
        )
      )}
    </>
  )
}

App.propTypes = {
  children: PropTypes.node.isRequired,
}
