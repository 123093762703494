import styled from "styled-components"
import { colors, breakpoints } from "../../Style"

export const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;

  @media ${breakpoints.medium} {
    width: 100%;
  }

  @media ${breakpoints.large} {
    width: auto;
  }
`

export const Column = styled.div`
  padding: 1.5rem;

  &:not(:last-child) {
    border-right: 0.1rem solid ${colors.greyD};
  }

  @media ${breakpoints.medium} {
    padding: 1.5rem 2rem;
    flex: 1;
  }

  @media ${breakpoints.large} {
    padding: 1.7rem 3rem;
    flex: auto;
  }
`
