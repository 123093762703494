import { useEffect, useState } from "react"
import * as THREE from "three"

export default function useCamera(mount) {
  // should sort the cameras in a better way, but I have no time
  const [camera, setCamera] = useState(null)
  const [target] = useState([
    { x: -178.2150250181623, y: 10, z: 469.55934081037293 },
    { x: 525.6476797279183, y: 10, z: 469.4732852378011 },
    { x: 546.8619243981336, y: 10, z: 95.4696763597908 },
    { x: 365.4091262862042, y: 10, z: -406.76600574356144 },
    { x: 93.05986559042597, y: 10, z: -106.69042919113592 },
    { x: -194.3344349966924, y: 10, z: 107.35915972602652 },
    { x: 51.999892757838566, y: 10, z: 115.14192460193469 },
  ])

  const [pov] = useState([
    { x: 2241.2903609488194, y: 948.6925778416521, z: -1109.1449181054004 },
    { x: 1470.0726324098932, y: 665.5240366732311, z: -1313.3193965104929 },
    { x: 1622.0227404144307, y: 665.5240366732311, z: -1611.6675346009433 },
    { x: 2016.4110558045866, y: 665.5240366732311, z: -1566.283520087438 },
    { x: 682.3145571494915, y: 501.476457732242, z: -1499.8038143986148 },
    { x: 573.2283285010219, y: 601.6104430975857, z: -1543.738236768062 },
    { x: 2770.5148788712263, y: 2084.671877132916, z: -5621.615790647612 },
  ])

  const [mobileTarget] = useState({ x: 165, y: 10, z: 142 })

  useEffect(() => {
    setCamera(
      new THREE.PerspectiveCamera(
        4.2951708628648655,
        mount.current.clientWidth / mount.current.clientHeight,
        50,
        10000
      )
    )
  }, [mount])

  useEffect(() => {
    if (camera) {
      const { current: element } = mount

      const adjustCamera = () => {
        // adjust camera aspect to window
        camera.aspect = element.clientWidth / element.clientHeight
        camera.updateProjectionMatrix()
      }

      const handleResize = () => adjustCamera()

      adjustCamera()

      window.addEventListener("resize", handleResize)

      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }
  }, [camera])

  return [camera, setCamera, target, pov, mobileTarget]
}
