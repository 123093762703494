import React, { useState } from "react"
import { useSpring } from "react-spring"

import { useStateValue } from "../../../state"

import {
  Container,
  LinkDetailContainer,
  Headline,
  Ul,
  Li,
  ButtonBack,
  ButtonReset,
} from "./Style"
import LinkDetail from "../../link-details/"
import Link from "../../link/"
import { SubTitle } from "../../Text"
import { Try } from "../../../utils"

export default function Body() {
  const [state, dispatch] = useStateValue()
  const [view, setView] = useState(1)
  const [activeCategory, setActiveCategory] = useState(null)
  const [activeLink, setActiveLink] = useState(null)

  const fadeIn = useSpring({
    to: {
      opacity: 1,
      transform: "translateY(0)",
    },
    from: { opacity: 0, transform: "translateY(10%)" },
  })

  const { content, current, length } = state.step

  const stepContent = content[current - 1] || null

  const handleCategories = () => {
    if (current > 0) {
      const { categories } = stepContent
      if (categories) {
        if (categories.length < 2) {
          const category = categories[0]
          const links = category.links

          if (links)
            return (
              <Ul spacing={activeCategory ? 1 : 0}>
                {links.map((link, i) => (
                  <Li key={`l-${i}`}>
                    <Link
                      id={i}
                      title={link.title}
                      intro={link.intro}
                      highlight={link.highlight}
                      onClick={() => {
                        setView(2)
                        setActiveLink(link)
                      }}
                    />
                  </Li>
                ))}
              </Ul>
            )
        }
        return (
          <Ul spacing={activeCategory ? 1 : 0}>
            {activeCategory
              ? categories[activeCategory - 1].links.map((link, i) => (
                  <Li key={`lc-${i}`}>
                    <Link
                      id={i}
                      title={link.title}
                      intro={link.intro}
                      highlight={link.highlight}
                      onClick={() => {
                        setView(2)
                        setActiveLink(link)
                      }}
                    />
                  </Li>
                ))
              : categories.map((category, i) => (
                  <Li key={`c-${i}`}>
                    <Link
                      id={i}
                      title={category.title}
                      empty={!category.links}
                      onClick={() => {
                        if (category.links) {
                          setActiveCategory(i + 1)
                        }
                      }}
                    />
                  </Li>
                ))}
          </Ul>
        )
      }
    }
    return null
  }

  const handleBody = () => {
    // show content for step 1+
    if (stepContent) {
      switch (view) {
        case 1:
          return (
            <>
              <Container style={fadeIn}>
                <Headline>{`${current}. ${stepContent.title}`}</Headline>
                <SubTitle>{Try(stepContent, "description")}</SubTitle>
                {current === length && (
                  <ButtonReset onClick={() => dispatch({ type: "STEP_RESET" })}>
                    Starta om
                  </ButtonReset>
                )}
              </Container>
              {activeCategory && (
                <ButtonBack
                  onClick={() => {
                    setView(1)
                    setActiveCategory(null)
                  }}
                />
              )}
              {handleCategories()}
            </>
          )
          break
        case 2:
          return (
            <LinkDetailContainer>
              <ButtonBack
                adjust={true}
                onClick={() => {
                  setView(1)
                  setActiveLink(null)
                }}
              />
              <LinkDetail
                title={activeLink.title}
                preamble={activeLink.preamble}
                text={activeLink.text}
                buttonText={activeLink.button_label}
                buttonHref={activeLink.url}
              />
            </LinkDetailContainer>
          )
          break
        default:
          break
      }
    }
    // close sidebar if we're going back to step 0 and the sidebar is opened
    if (state.sidebar.isOpen) {
      dispatch({ type: "SIDEBAR_CLOSE" })
    }
  }

  return <>{handleBody()}</>
}
