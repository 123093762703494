import styled from "styled-components"
import { colors, zIndex } from "../../Style"
import { hexToRgb } from "../../../utils/"
import Button from "../../Button"

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${zIndex.progress};
  display: flex;
  padding: 1rem 0.75rem;
  margin: 0 -0.75rem;
  box-shadow: 0 -0.2rem 0 rgba(${hexToRgb(colors.blue).rgb}, 0.1);
  background-color: rgba(${hexToRgb(colors.white).rgb}, 0.7);
`

export const Column = styled.div`
  flex-flow: row nowrap;
  flex: 0 50%;
  padding: 0 0.75rem;
`

export const Btn = styled(Button)`
  width: 100%;
  min-width: 0;
  max-width: 100%;
`
