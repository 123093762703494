import { animated } from "react-spring"
import styled from "styled-components"

import { colors, zIndex, breakpoints } from "../Style"
import { hexToRgb } from "../../utils/"

export const Overlay = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  overflow-y: scroll;
  align-items: center;
  min-height: 100vh;
  padding: 15rem 1.5rem 10rem;
  z-index: ${zIndex.startup};
  background-color: rgba(58, 58, 58, 0.25);
  background: linear-gradient(
    0deg,
    rgba(33, 33, 33, 1) 20%,
    rgba(0, 212, 255, 0) 100%
  );
  box-shadow: 0 3rem 8rem 0 rgba(0, 0, 0, 0.2),
    inset 0 30rem 20rem 0 rgba(0, 0, 0, 0.25);
  pointer-events: ${props => (props.display ? "all" : "none")};

  @media ${breakpoints.medium} {
    padding: 3rem;
    background: rgba(58, 58, 58, 0.25);
  }
`

export const Content = styled(animated.div)`
  position: relative;
  width: 100%;
  max-width: 84rem;
  padding: 2rem;
  margin: auto;
  background-color: rgba(${hexToRgb(colors.white).rgb}, 0.95);
  text-align: center;

  @media ${breakpoints.medium} {
    padding-bottom: 7.3rem;
    background-color: rgba(${hexToRgb(colors.white).rgb}, 0.85);
  }
`
