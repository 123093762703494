import React from "react"

import { useStateValue } from "../../state"
import { Container, DotWrapper, Dot, Input } from "./Style"

export default function Indicators({ index }) {
  const [state, dispatch] = useStateValue()

  const { content, current } = state.step

  return (
    <Container>
      {content.map((step, i) => (
        <DotWrapper key={i} htmlFor={`radio-step-${i}`}>
          <Input
            {...(i === 0 ? { defaultChecked: true } : {})}
            id={`radio-step-${i}`}
            active={i === current - 1}
            aria-label={`Hoppa till steget ${i + 1}`}
            title={`Steg ${i + 1}`}
            type="radio"
            name="step"
            onClick={() => {
              dispatch({
                type: "STEP_SET",
                payload: {
                  current: i + 1,
                },
              })
            }}
          />
          <Dot active={i === current - 1} />
        </DotWrapper>
      ))}
    </Container>
  )
}
