import * as THREE from "three"

export default function setLights({ scene, renderer }) {
  renderer.shadowMap.enabled = true
  renderer.shadowMap.type = THREE.PCFSoftShadowMap // default THREE.PCFShadowMap
  // increase brightness of all objects in the scene
  const brightness = new THREE.AmbientLight(0xffffff, 0.15) // soft white light
  scene.add(brightness)
  // add lights in the sky
  const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444)
  scene.add(hemiLight)

  const spotLight = new THREE.SpotLight(0xffffff, 0.1)

  spotLight.position.set(-997.511, 1000, -826.793)
  // spotLight.castShadow = true

  spotLight.shadow.mapSize.width = 5000
  spotLight.shadow.mapSize.height = 5000

  spotLight.shadow.camera.near = 50
  spotLight.shadow.camera.far = 5000
  spotLight.shadow.camera.fov = 4.2951708628648655

  scene.add(spotLight)
}
