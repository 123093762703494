import React from "react"
import { useSpring } from "react-spring"
import { useStateValue } from "../../../state"
import { AnimatedButton, Icon } from "./Style"

export default function SidebarTrigger() {
  const [state, dispatch] = useStateValue()
  const { current } = state.step

  const props = useSpring({
    opacity: state.sidebar.isOpen || current < 1 ? 0 : 1,
    from: {
      opacity: state.sidebar.isOpen || current < 1 ? 1 : 0,
    },
  })

  return (
    <AnimatedButton
      style={props}
      onClick={() => {
        dispatch({
          type: "SIDEBAR_OPEN",
        })
      }}
    >
      <Icon icon={["far", "bars"]} width={20} height={20} />
    </AnimatedButton>
  )
}
