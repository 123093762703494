export const hideObjs = (...items) => {
  items.forEach(item => hideObj(item))
}

export const hideObj = obj => {
  obj.visible = false
}

export const isVisible = obj => obj.visible

export const showObjs = (...items) => {
  items.forEach(item => showObj(item))
}

export const showObj = obj => {
  obj.visible = true
}
