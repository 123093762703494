import { useEffect, useState } from "react"

export const limit = 769

export default function useMobile() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < limit)

  const mobileCheck = () => {
    setIsMobile(window.innerWidth < limit)
  }

  useEffect(() => {
    window.addEventListener("resize", mobileCheck)
    return () => {
      window.removeEventListener("resize", mobileCheck)
    }
  }, [])

  return [isMobile]
}
